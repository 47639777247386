

/* .fc-daygrid-week-view {
  height: calc(50vh - 3rem) !important; } */
.fc-media-screen {
  height: calc(70vh) !important; 
}
.fc-daygrid-day-number {
  font-size: 0.8em;
}
.fc-event-title-container{
  background-color: #f7cc11;
  color: black;
  text-align: center;
  border-bottom-color: none;
  border-top-color: none;
  border-left-color: none;
  border-right-color: none;
}
.fc-daygrid-day-frame {
  min-height: 5px;
  margin: 0;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #1F2937;
  color: white;
}
.fc .fc-button-primary {
  background-color: #1F2937;
  color: white;
}
.fc .fc-button-primary:hover {
  background-color: #f7cc11;
  color: black;
}
.fc .fc-button-primary:active {
  background-color: #f7cc11;
  color: black;
}
.fc .fc-button-primary:disabled {
  background-color: #1F2937;
  color: white;
} 
.fancy-countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-family: Arial, sans-serif;
  flex-wrap: wrap;
}

.flip-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flip-card {
  background: linear-gradient(135deg, #f8f9fa, #e0e0e0, #f8f9fa);
  width: 100px;
  height: 120px;
  perspective: 1000px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  color: #333;
}

.flip-card-back {
  transform: rotateX(180deg);
}

.flip-card-label {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #666;
}